import { AnyAction } from '@reduxjs/toolkit';

const initState: any = {
  user: null,
  accessToken: null,
  refreshSessionFn: null,
  isInitialized: false,
};

const reducer = (state = initState, action: AnyAction) => {
  switch (action.type) {
    case 'USER_LOADED': {
      return {
        ...state,
        user: action.payload,
      };
    }
    case 'ACCESS_TOKEN_LOADED': {
      return {
        ...state,
        accessToken: action.payload.result,
        refreshSessionFn: action.payload.refreshSessionFn,
      };
    }
    case 'APP_INITIALIZED': {
      return {
        ...state,
        isInitialized: true,
      };
    }
  }
  return state;
};
export default reducer;
