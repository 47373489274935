import * as React from 'react';
import { Route, Routes } from 'react-router-dom';
import CampaignListPage from '../pages/campaign-list/CampaignList.page';
import CampaignUploadDetailsPage from '../pages/campaign-upload-details/CampaignUploadDetails.page';
import Dashboard from '../pages/dashboard';
import CampaignUploadDetailsPageV2 from '../pages/campaign-upload-details/CampaignUploadDetailsV2.page';

const RoutingProvider = (): JSX.Element => {
  return (
    <Routes>
      <Route path='/' element={<Dashboard />} />
      <Route
        path='/campaigns/:campaignId'
        element={<CampaignUploadDetailsPage />}
      />
      <Route path='/campaigns' element={<CampaignListPage />} />
      <Route
        path='/producer/:campaignId'
        element={<CampaignUploadDetailsPageV2 />}
      />
    </Routes>
  );
};

export default React.memo(RoutingProvider);
