import { Grommet } from 'grommet';
import React from 'react';
import * as ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import './index.css';
import { defaultTheme } from './theme';
import store from './redux/store/index';
import { Provider as ReduxProvider } from 'react-redux';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import CognitoProvider from './components/Cognito/CognitoProvider';

const AppWithLaunchDarkly = () => {
  const LDProviderWrappedApp = withLDProvider({
    clientSideID: String(process.env.REACT_APP_LAUNCH_DARKLY_KEY),
  })(App);
  return <LDProviderWrappedApp />;
};

ReactDOM.render(
  <React.StrictMode>
    <Grommet theme={defaultTheme}>
      <ReduxProvider store={store}>
        <BrowserRouter>
          <CognitoProvider>
            <AppWithLaunchDarkly />
          </CognitoProvider>
        </BrowserRouter>
      </ReduxProvider>
    </Grommet>
  </React.StrictMode>,
  document.getElementById('root'),
);
