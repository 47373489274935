import { User } from '../../components/Cognito/CognitoContext';

export const refreshSessionAction: any =
  (refreshSessionFn: () => Promise<{ accessToken: string }>) =>
  (dispatch: any) => {
    return refreshSessionFn().then((tokens) => {
      dispatch({
        type: 'ACCESS_TOKEN_LOADED',
        payload: { result: tokens.accessToken, refreshSessionFn },
      });
    });
  };

export const getAppInitializedAction: any =
  (
    user: User,
    token: string,
    refreshSessionFn: () => Promise<{ accessToken: string }>,
  ) =>
  (dispatch: any) => {
    dispatch({
      type: 'USER_LOADED',
      payload: user,
    });
    dispatch({
      type: 'ACCESS_TOKEN_LOADED',
      payload: { result: token, refreshSessionFn },
    });
    dispatch({
      type: 'APP_INITIALIZED',
    });
  };
