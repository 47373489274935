import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  matchPath,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AdminHeader from './components/admin-header/AdminHeader.component';
import RoutingProvider from './providers/RoutingProvider';
import { getAppInitializedAction } from './redux/actions/user-actions';
import { getJourneyAction } from './redux/actions/soil-campaign-actions';
import useCognito from './components/Cognito/UseCognito';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Box, Spinner, Text } from 'grommet';

function App() {
  let navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { adminPortalAccess } = useFlags();
  const salesforceId = searchParams.get('salesforceId');
  const journeyId = searchParams.get('journeyId');
  const { getTokensOrLogin, refreshSessionSilently } = useCognito();
  const dispatch = useDispatch();
  const ldClient = useLDClient();
  const producer = useSelector((state: any) => state.soilCampaign.producer);
  const journey = useSelector((state: any) => state.soilCampaign.journey);
  const token = useSelector((state: any) => state.user.accessToken);
  const userData = useSelector((state: any) => state.user.user);
  const isInitialized = useSelector((state: any) => state.user.isInitialized);

  useEffect(() => {
    getTokensOrLogin().then(({ token, user }) => {
      dispatch(getAppInitializedAction(user, token, refreshSessionSilently));
    });
  }, [dispatch, getTokensOrLogin, refreshSessionSilently]);

  useEffect(() => {
    if (salesforceId && producer && !journeyId && token) {
      dispatch(getJourneyAction(producer.leadId, producer.opportunityId));
    } else if (journeyId && !journey && token) {
      dispatch(getJourneyAction(null, null, journeyId));
    }
  }, [salesforceId, producer, journeyId, journey, dispatch, token]);

  useEffect(() => {
    if (!journeyId && journey) {
      setSearchParams({ journeyId: journey.journeyId }, { replace: true });
    }
  }, [journey, journeyId, setSearchParams]);

  const goBack = useCallback(() => {
    dispatch({
      type: 'CAMPAIGN_SEARCH_UPDATE_REQUESTED',
      payload: '',
    });
    if (matchPath({ path: '/campaigns/:id' }, pathname) && producer) {
      if (journeyId || journey) {
        return navigate(
          `/campaigns?journeyId=${journeyId || journey.journeyId}`,
        );
      }
      return navigate(`/campaigns?salesforceId=${producer['Id']}`);
    }
    if (
      salesforceId ||
      journeyId ||
      matchPath({ path: '/campaigns/:id' }, pathname)
    ) {
      return navigate(`/campaigns`);
    }
    navigate('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, salesforceId, pathname, producer]);

  const getBackButtonLabel = useCallback(() => {
    if (matchPath({ path: '/campaigns/:id' }, pathname) && producer) {
      return 'Back to producer list';
    }
    if (
      salesforceId ||
      journeyId ||
      matchPath({ path: '/campaigns/:id' }, pathname)
    ) {
      return 'Back to full list';
    }
    return 'Back to Overview';
  }, [pathname, salesforceId, journeyId, producer]);

  useEffect(() => {
    if (ldClient && userData) {
      ldClient.identify({
        kind: 'user',
        // Using email as key since it way implemented this way from the beginning
        key: userData.email,
        firstName: userData.firstName,
        lastName: userData.lastName,
        name: userData.fullName,
        email: userData.email,
        country: userData.country,
        roles: userData.roles,
        partnerTier: userData.partnerTier,
      });
    }
  }, [ldClient, userData]);

  if (!adminPortalAccess) {
    return (
      <Box height={'100vh'} justify={'center'} align={'center'}>
        {adminPortalAccess === undefined ? (
          <Spinner size={'small'} color={'#FFE137'} />
        ) : (
          <Box height={'100vh'} width={'100vw'}>
            <AdminHeader
              backButtonCallback={goBack}
              backButtonTitle={getBackButtonLabel()}
              backNavigate={!matchPath(pathname, '/')}
            />
            <Box height={'100%'} align={'center'} justify={'center'}>
              <Text size={'2em'} weight={800}>
                You do not have access to the Admin Portal
              </Text>
              <Text size={'1.5em'}>
                Please reach out to your superior if you need access.
              </Text>
            </Box>
          </Box>
        )}
      </Box>
    );
  }

  return isInitialized ? (
    <div className='App'>
      <ToastContainer
        position='top-right'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {!matchPath({ path: '/producer/:campaignId' }, pathname) ? (
        <AdminHeader
          backButtonCallback={goBack}
          backButtonTitle={getBackButtonLabel()}
          backNavigate={!matchPath(pathname, '/')}
        />
      ) : (
        <></>
      )}
      <RoutingProvider />
    </div>
  ) : (
    <Spinner />
  );
}

export default App;
