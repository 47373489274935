import { Heading, Page, Grid } from 'grommet';
import { useNavigate } from 'react-router-dom';
import ViewCampaigns from '../../assets/agoro-graphics/view-campaigns.svg';
import ImageCard from '../../components/ImageCard';
import { useSelector } from 'react-redux';
import { User } from '../../components/Cognito/CognitoContext';

const getCampaignCard = (buttonCallback: any) => {
  return (
    <ImageCard
      dataCy={'ss-campaign-card'}
      imageSrc={ViewCampaigns}
      title='Soil Sampling Campaigns'
      description={`View & Create your Campaigns`}
      buttonText='View Campaigns'
      buttonCallback={buttonCallback}
    />
  );
};
const Dashboard = () => {
  const user: User = useSelector((state: any) => state.user.user);
  const navigate = useNavigate();
  return (
    <Page margin='large' gap='large'>
      <Heading size='small' fill={true}>
        Hi {user.fullName}, what do you want to work on?
      </Heading>
      <Grid
        columns={{
          count: 5,
          size: 'auto',
        }}
        gap='small'
      >
        {getCampaignCard(() => {
          navigate('/campaigns');
        })}
      </Grid>
    </Page>
  );
};
export default Dashboard;
