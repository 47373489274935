import { useCallback, useEffect, useRef, useState } from 'react';
import 'mapbox-gl/dist/mapbox-gl.css';
import { showToast } from '../../../providers/ToastProvider';
import { campaignService } from '../../../service/campaign.service';
import { ReactComponent as MarkerApprovedIcon } from '../assets/marker-approved-icon.svg';
import { ReactComponent as MarkerRejectedIcon } from './../assets/marker-rejected-icon.svg';
import { ReactComponent as MarkerInReviewIcon } from './../assets/marker-icon.svg';
import { ReactComponent as MarkerSkippedIcon } from './../assets/marker-skipped-icon.svg';
import { ReactComponent as StratificationZonesIcon } from './../assets/stratification-zones.svg';
import { ReactComponent as FieldBoundariesIcon } from './../assets/field-boundaries.svg';
import {
  MapService,
  SAMPLING_POINT_STATUS,
} from '../../../service/map.service';
import MapAdaptViewComponent from './MapAdaptView.component';
import {
  Box,
  Button,
  Meter,
  Sidebar,
  Stack,
  Text,
  Nav,
  Spinner,
} from 'grommet';

import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import ChangeStatusInBulkComponent from './ChangeStatusInBulk.component';
import PointsCounterComponent from './PointsCounter.component';
import PointDataComponent from './PointData.component';
import { format } from 'date-fns';
import { Statuses } from '../utils/enums';
import { StyledTooltip } from '../../../components/common/StyledTooltip';
import { useSelector } from 'react-redux';

// @ts-ignore
import mapboxgl from 'mapbox-gl/dist/mapbox-gl-csp';
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
import MapboxWorker from 'worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker';
mapboxgl.workerClass = MapboxWorker;

mapboxgl.accessToken = String(process.env.REACT_APP_MAP_KEY);

const ReviewPointsTabComponent = (data: any) => {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [lng] = useState(-103.9048);
  const [lat] = useState(38.9915);
  const [strataProgress, setStrataProgress] = useState(-1);
  const [zoom] = useState(3);
  const [showFieldBoundaries, setShowFieldBoundaries] = useState(false);
  const [showStrataZones, setShowStrataZones] = useState(false);
  const [adaptViewOpened, setAdaptViewOpened] = useState(false);
  const [disablePointEdit, setDisablePointEdit] = useState(
    data.campaignData?.status !== Statuses['in-review'],
  );

  const [showInReviewLocations, setShowInReviewLocations] = useState(true);
  const [showApprovedLocations, setShowApprovedLocations] = useState(true);
  const [showRejectedLocations, setShowRejectedLocations] = useState(true);
  const [showSkippedLocations, setShowSkippedLocations] = useState(true);

  const [pointHistory, setPointHistory] = useState(null);

  const { campaignCsvGenerationState, sendCampaignToDeveron } = useSelector(
    (state: any) => {
      return {
        campaignCsvGenerationState: state.soilCampaign.campaignCsvGeneration,
        sendCampaignToDeveron: state.soilCampaign.sendCampaignToDeveron,
      };
    },
  );

  const win: any = window;
  win.showApprovedLocations = showApprovedLocations;
  win.showRejectedLocations = showRejectedLocations;
  win.showInReviewLocations = showInReviewLocations;
  win.showSkippedLocations = showSkippedLocations;

  const [locationsGeoJSON, setLocationsGeoJSON] = useState(null);
  const [pointsCounterData, setPointsCounterData] = useState([
    {
      icon: <MarkerApprovedIcon width={30} height={30} />,
      label: 'Approved',
      value: 'APPROVED',
      currentPoints: data.campaignData.samplingPoints.filter(
        (i: any) => i.status === 'APPROVED',
      ).length,
      totalPoints: data.campaignData.samplingPoints.length,
    },
    {
      icon: <MarkerInReviewIcon width={30} height={30} />,
      label: 'In Review',
      value: 'IN_REVIEW',
      currentPoints: data.campaignData.samplingPoints.filter(
        (i: any) => i.status === 'IN_REVIEW',
      ).length,
      totalPoints: data.campaignData.samplingPoints.length,
    },
    {
      icon: <MarkerRejectedIcon width={30} height={30} />,
      label: 'Rejected',
      value: 'REJECTED',
      currentPoints: data.campaignData.samplingPoints.filter(
        (i: any) => i.status === 'REJECTED',
      ).length,
      totalPoints: data.campaignData.samplingPoints.length,
    },
    {
      icon: <MarkerSkippedIcon width={30} height={30} />,
      label: 'Skipped',
      value: 'SKIPPED',
      currentPoints: data.campaignData.samplingPoints.filter(
        (i: any) => i.status === 'SKIPPED',
      ).length,
      totalPoints: data.campaignData.samplingPoints.length,
    },
  ]);
  const pointsRef = useRef(data.campaignData.samplingPoints);

  useEffect(() => {
    pointsRef.current = data.campaignData.samplingPoints;
    pointsRef.current.forEach((point: any) => {
      const marker = document.getElementById('point-id-' + point.id);
      if (!marker) return;

      marker.classList.remove('marker-approved');
      marker.classList.remove('marker-in-review');
      marker.classList.remove('marker-rejected');
      marker.classList.remove('marker-skipped');
      switch (point.status) {
        case 'APPROVED':
          marker.classList.add('marker-approved');
          break;
        case 'REJECTED':
          marker.classList.add('marker-rejected');
          break;
        case 'SKIPPED':
          marker.classList.add('marker-skipped');
          break;
        default:
          marker.classList.add('marker-in-review');
      }
    });
    const newPointsCounterData = [...pointsCounterData];
    newPointsCounterData[0].currentPoints = pointsRef.current.filter(
      (i: any) => i.status === 'APPROVED',
    ).length;
    newPointsCounterData[1].currentPoints = pointsRef.current.filter(
      (i: any) => i.status === 'IN_REVIEW',
    ).length;
    newPointsCounterData[2].currentPoints = pointsRef.current.filter(
      (i: any) => i.status === 'REJECTED',
    ).length;
    setPointsCounterData(newPointsCounterData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.campaignData.samplingPoints]);

  const getHistory = useCallback(
    (pointSelected: any) => {
      setPointHistory(null);
      campaignService
        .getPointLogs(pointSelected.campaignId, pointSelected.pointId)
        .then((res) => {
          let logs: any[] = [];
          res.data.forEach((result: any) => {
            const dateFormatted = format(
              new Date(result.createdAt),
              'dd.MM.yyyy',
            );
            const log = logs.find(
              (log) =>
                log.dateFormatted === dateFormatted &&
                log.user.name === result.user.name,
            );
            const type =
              result.actionType === 'POINT_APPROVED' ||
              result.actionType === 'POINT_REJECTED' ||
              result.actionType === 'POINT_IN_REVIEW'
                ? 'STATUS_CHANGE'
                : result.actionType;
            if (log) {
              log.actions.push({
                id: log.actions?.length || 0,
                type: type,
                value: getLogValue(result),
              });
            } else {
              logs.push({
                id: logs.length,
                user: { name: result.user.name },
                date: result.createdAt,
                dateFormatted: dateFormatted,
                actions: [
                  {
                    id: log?.actions?.length || 0,
                    type: type,
                    value: getLogValue(result),
                  },
                ],
              });
            }
          }, {});
          setPointHistory(logs);
          const newPointsCounterData = [...pointsCounterData];
          newPointsCounterData[0].currentPoints =
            data.campaignData.samplingPoints.filter(
              (i: any) => i.status === 'APPROVED',
            ).length;
          newPointsCounterData[1].currentPoints =
            data.campaignData.samplingPoints.filter(
              (i: any) => i.status === 'IN_REVIEW',
            ).length;
          newPointsCounterData[2].currentPoints =
            data.campaignData.samplingPoints.filter(
              (i: any) => i.status === 'REJECTED',
            ).length;
          setPointsCounterData(newPointsCounterData);
        })
        .catch((err) => {
          console.log(err);
          showToast(
            'Error',
            'Sorry, but we could not load the history for this sampling point. Try again later!',
            'error',
          );
          setPointHistory([]);
        });
    },
    [data.campaignData.samplingPoints, pointsCounterData],
  );

  const onAdaptViewOpened = () => {
    setAdaptViewOpened(true);
  };
  const onAdaptVieClosed = () => {
    setAdaptViewOpened(false);
  };

  const filterPointsInMap = useCallback(() => {
    const points: any = { type: 'FeatureCollection', features: [] };
    const win: any = window;
    for (const samplingPoint of data.campaignData.samplingPoints) {
      if (win.showApprovedLocations && samplingPoint.status === 'APPROVED') {
        points.features.push({
          geometry: {
            coordinates: [samplingPoint.longitude, samplingPoint.latitude],
            type: 'Point',
          },
          type: 'Feature',
          properties: samplingPoint,
        });
      } else if (
        win.showRejectedLocations &&
        samplingPoint.status === 'REJECTED'
      ) {
        points.features.push({
          geometry: {
            coordinates: [samplingPoint.longitude, samplingPoint.latitude],
            type: 'Point',
          },
          type: 'Feature',
          properties: samplingPoint,
        });
      } else if (
        win.showInReviewLocations &&
        samplingPoint.status === 'IN_REVIEW'
      ) {
        points.features.push({
          geometry: {
            coordinates: [samplingPoint.longitude, samplingPoint.latitude],
            type: 'Point',
          },
          type: 'Feature',
          properties: samplingPoint,
        });
      } else if (
        win.showSkippedLocations &&
        samplingPoint.status === 'SKIPPED'
      ) {
        points.features.push({
          geometry: {
            coordinates: [samplingPoint.longitude, samplingPoint.latitude],
            type: 'Point',
          },
          type: 'Feature',
          properties: samplingPoint,
        });
      }
    }
    points.features.map((x: any) => {
      delete x.properties.marker;
      return x;
    });
    map.current.getSource('samplingPointsSource').setData(points);
  }, [data]);

  //TODO remove when history is completed
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const pointStatusChangedListener = async (
    marker: mapboxgl.Marker,
    feature: any,
    status: any,
  ) => {
    const point = data.campaignData.samplingPoints.find(
      (i: any) => i.pointId === feature.pointId,
    );
    point.status = status.value;
    try {
      await campaignService.updateSamplingPoint(
        feature.campaignId,
        feature.pointId,
        { status: status.value },
      );
      filterPointsInMap();

      showToast(
        'Success',
        `Sample location status was updated to ${status.label} successfully`,
        'success',
      );
    } catch (e) {
      showToast(
        'Error',
        'Sorry, but we could not update the status for this point. Try again later!',
        'error',
      );
    }
  };

  const onMarkerDragEnd = useCallback(
    (marker: mapboxgl.Marker, feature: any) => {
      const lngLat = marker.getLngLat();
      const originalPosition = {
        latitude: feature.latitude,
        longitude: feature.longitude,
      };
      if (data.campaignData?.status !== Statuses['in-review']) {
        marker.setLngLat([
          originalPosition.longitude,
          originalPosition.latitude,
        ]);
        return;
      }
      feature.latitude = lngLat.lat;
      feature.longitude = lngLat.lng;
      const point = data.campaignData.samplingPoints.find(
        (i: any) => i.pointId === feature.pointId,
      );
      point.latitude = lngLat.lat;
      point.longitude = lngLat.lng;
      data.onChangePoints(data.campaignData);
      const pointLocation = {
        latitude: lngLat.lat,
        longitude: lngLat.lng,
      };
      campaignService
        .updateSamplingPoint(feature.campaignId, feature.pointId, pointLocation)
        .then(() => {
          showToast(
            'Success',
            'Sample location was updated successfully',
            'success',
            `${feature.pointId}${pointLocation.longitude}${pointLocation.latitude}`,
            'Undo change',
            async () => {
              marker.setLngLat([
                originalPosition.longitude,
                originalPosition.latitude,
              ]);
              await campaignService.updateSamplingPoint(
                feature.campaignId,
                feature.pointId,
                originalPosition,
              );
              getHistory(feature);
            },
          );
          getHistory(feature);
        })
        .catch(() => {
          showToast(
            'Error',
            'Sorry, but we could not update this point. Try again later!',
            'error',
          );
        });
    },
    [data, getHistory],
  );

  const [currentPointSelected, setCurrentPointSelected] = useState(null);
  const [isMultiSelectMode, setIsMultiselectMode] = useState(false);

  useEffect(() => {
    const win: any = window;
    win.currentPointSelected = currentPointSelected;
    win.isMultiSelectMode = isMultiSelectMode;
  }, [currentPointSelected, isMultiSelectMode]);

  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/satellite-streets-v11',
      center: [lng, lat],
      zoom: zoom,
      minZoom: 2,
    });

    const nav = new mapboxgl.NavigationControl({
      showCompass: false,
    });
    map.current.addControl(nav, 'bottom-right');

    map.current.on('click', (e: any) => {
      if (e.defaultPrevented === false) {
        setCurrentPointSelected(null);
        filterPointsInMap();
      }
    });

    const points: any = { type: 'FeatureCollection', features: [] };
    for (const samplingPoint of data.campaignData.samplingPoints) {
      points.features.push({
        geometry: {
          coordinates: [samplingPoint.longitude, samplingPoint.latitude],
          type: 'Point',
        },
        type: 'Feature',
        properties: samplingPoint,
      });
    }
    setLocationsGeoJSON(points);

    map.current.on('load', () => {
      let markersOnScreen: any = {};
      const markersUnClustered: any = {};

      function updateMarkers() {
        const newMarkers: any = {};
        const features = map.current.querySourceFeatures(
          'samplingPointsSource',
        );
        // for every cluster on the screen, create an HTML marker for it (if we didn't yet),
        // and add it to the map if it's not there already
        for (const feature of features) {
          const coords = feature.geometry.coordinates;
          const props = feature.properties;
          if (props.cluster || !props.pointId) continue;
          const id = props.pointId;

          let marker = markersUnClustered[id];
          const point = pointsRef.current.find((i: any) => i.pointId === id);
          let className = '';
          switch (point.status) {
            case 'APPROVED':
              className = 'marker-approved';
              break;
            case 'REJECTED':
              className = 'marker-rejected';
              break;
            case 'SKIPPED':
              className = 'marker-skipped';
              break;
            default:
              className = 'marker-in-review';
          }

          if (!marker) {
            const el = document.createElement('div');
            el.className = `marker ${className}`;
            el.id = 'point-id-' + point.id;
            marker = markersUnClustered[id] = new mapboxgl.Marker({
              element: el,
              draggable: data.campaignData?.status === Statuses['in-review'],
            }).setLngLat(coords);
            marker.getElement().onclick = () => {
              setTimeout(() => {
                point.marker = marker;
                setCurrentPointSelected(point);
                marker.getElement().classList.add('marker-selected');
              }, 100);
            };

            marker.on('dragend', () => {
              onMarkerDragEnd(marker, point);
            });
          } else {
            marker.getElement().classList.remove('marker-approved');
            marker.getElement().classList.remove('marker-in-review');
            marker.getElement().classList.remove('marker-rejected');
            marker.getElement().classList.remove('marker-skipped');
            marker.getElement().classList.add(className);
          }
          newMarkers[id] = marker;

          if (!markersOnScreen[id]) marker.addTo(map.current);
        }

        // for every marker we've added previously, remove those that are no longer visible
        for (const id in markersOnScreen) {
          if (!newMarkers[id]) markersOnScreen[id].remove();
        }
        markersOnScreen = newMarkers;

        const win: any = window;
        for (const id in newMarkers) {
          newMarkers[id].setDraggable(false);
          if (
            win.currentPointSelected &&
            win.currentPointSelected.pointId === id &&
            !win.isMultiSelectMode
          ) {
            newMarkers[id].getElement().classList.add('marker-selected');
          } else {
            newMarkers[id].getElement().classList.remove('marker-selected');
          }
        }
        setCurrentMarkers(newMarkers);
      }

      const onStrataLoadProgress = (percent: number) => {
        if (percent >= 0) {
          setStrataProgress(percent);
        }
        if (percent >= 100) {
          setTimeout(() => {
            setStrataProgress(-1);
          }, 5000);
        }
      };

      MapService.addStrataLayerToMap(
        data.campaignData.strataUrl,
        map.current,
        onStrataLoadProgress,
      );
      points.features.map((x: any) => {
        delete x.properties.marker;
        return x;
      });
      MapService.addSamplingPointsSourceToMap(map.current, points);

      map.current.on('render', () => {
        if (!map.current.isSourceLoaded('samplingPointsSource')) return;
        updateMarkers();
      });
      const win: any = window;
      win.map = map.current;
    });
  }, [
    map,
    lat,
    lng,
    zoom,
    data,
    onMarkerDragEnd,
    currentPointSelected,
    filterPointsInMap,
    getHistory,
    pointStatusChangedListener,
  ]);

  const isMapStyleLoaded = map.current?.isStyleLoaded();
  useEffect(() => {
    if (
      !map.current?.isStyleLoaded() ||
      map.current?.getSource('field-boundaries') ||
      !data.campaignData.boundaryUrl
    ) {
      return;
    }
    MapService.addBoundariesLayerToMap(
      data.campaignData.boundaryUrl,
      map.current,
    );
  }, [isMapStyleLoaded, data.campaignData.boundaryUrl]);

  const getLogValue = (log: any) => {
    switch (log.actionType) {
      case 'POINT_COMMENT_ADDED':
        return log.comment;
      case 'POINT_APPROVED':
        return 'Approved';
      case 'POINT_REJECTED':
        return 'Rejected';
      case 'POINT_IN_REVIEW':
        return 'In Review';
      case 'POINT_MOVED':
        const oldCoords = `${log.previousValue.longitude.toFixed(
          5,
        )}/${log.previousValue.latitude.toFixed(5)}`;
        const newCoords = `${(
          log.newValue.longitude || log.previousValue.longitude
        ).toFixed(5)}/${(
          log.newValue.latitude || log.previousValue.latitude
        ).toFixed(5)}`;
        return { oldCoords: oldCoords, newCoords: newCoords };
      default:
        return '';
    }
  };

  useEffect(() => {
    if (currentPointSelected) {
      getHistory(currentPointSelected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPointSelected]);

  const mapOverlayOptions = [];
  mapOverlayOptions.push({
    checked: showFieldBoundaries,
    label: 'Show field boundaries',
    dataCy: 'field-boundaries-toggle',
    icon: (
      <FieldBoundariesIcon
        style={{ paddingLeft: '7px', paddingRight: '3px' }}
      />
    ),
    onChange: (event: any) => {
      const hasBoundariesImported = data.campaignData.boundaryUrl;
      if (!hasBoundariesImported) {
        showToast(
          'Warning',
          'No field boundaries were found, import them first.',
          'warning',
        );
        return;
      }
      const checked = event.target.checked;
      setShowFieldBoundaries(checked);
      let visibility = checked ? 'visible' : 'none';
      // FIXME setTimeout to avoid toggle freezing
      setTimeout(() => {
        map.current.setLayoutProperty(
          'field-boundaries',
          'visibility',
          visibility,
        );
      }, 300);
    },
  });

  mapOverlayOptions.push({
    checked: showStrataZones,
    label: 'Show stratification zones',
    dataCy: 'stratification-zones-toggle',
    icon: <StratificationZonesIcon style={{ width: '40px', height: '40px' }} />,
    onChange: (event: any) => {
      const checked = event.target.checked;
      setShowStrataZones(checked);
      let visibility = checked ? 'visible' : 'none';
      // FIXME setTimeout to avoid toggle freezing
      setTimeout(() => {
        map.current.setLayoutProperty('field-strata', 'visibility', visibility);
      }, 300);
    },
  });

  const samplePointOptions = [];
  samplePointOptions.push({
    checked: showInReviewLocations,
    label: 'Show in review locations',
    dataCy: 'show-in-review-toggle',
    icon: <MarkerInReviewIcon style={{ width: '36px', height: '36px' }} />,
    onChange: (event: any) => {
      setShowInReviewLocations(event.target.checked);
    },
  });
  samplePointOptions.push({
    checked: showApprovedLocations,
    label: 'Show approved locations',
    dataCy: 'show-approved-toggle',
    icon: <MarkerApprovedIcon style={{ width: '36px', height: '36px' }} />,
    onChange: (event: any) => {
      setShowApprovedLocations(event.target.checked);
    },
  });
  samplePointOptions.push({
    checked: showRejectedLocations,
    label: 'Show rejected locations',
    dataCy: 'show-rejected-toggle',
    icon: <MarkerRejectedIcon style={{ width: '36px', height: '36px' }} />,
    onChange: (event: any) => {
      setShowRejectedLocations(event.target.checked);
    },
  });

  samplePointOptions.push({
    checked: showSkippedLocations,
    label: 'Show skipped locations',
    dataCy: 'show-skipped-toggle',
    icon: <MarkerSkippedIcon style={{ width: '36px', height: '36px' }} />,
    onChange: (event: any) => {
      setShowSkippedLocations(event.target.checked);
    },
  });

  useEffect(() => {
    if (
      !map ||
      !map.current ||
      !locationsGeoJSON ||
      !map.current.getSource('samplingPointsSource')
    )
      return;

    const filterMapLocations = () => {
      setCurrentPointSelected(null);
      locationsGeoJSON.features.map((x: any) => {
        delete x.properties.marker;
        return x;
      });
      const geoJSONFiltered = JSON.parse(JSON.stringify(locationsGeoJSON));
      const status: string[] = [];
      if (showApprovedLocations) {
        status.push('APPROVED');
      }
      if (showRejectedLocations) {
        status.push('REJECTED');
      }
      if (showInReviewLocations) {
        status.push('IN_REVIEW');
      }
      if (showSkippedLocations) {
        status.push('SKIPPED');
      }
      geoJSONFiltered.features = locationsGeoJSON.features.filter(
        (feature: any) => status.includes(feature.properties.status),
      );

      map.current.getSource('samplingPointsSource').setData(geoJSONFiltered);
    };
    const win: any = window;
    win.showApprovedLocations = showApprovedLocations;
    win.showRejectedLocations = showRejectedLocations;
    win.showInReviewLocations = showInReviewLocations;
    win.showSkippedLocations = showSkippedLocations;

    filterMapLocations();
  }, [
    showInReviewLocations,
    showApprovedLocations,
    showRejectedLocations,
    showSkippedLocations,
    locationsGeoJSON,
    map,
  ]);

  const [drawControl, setDrawControl] = useState<any>(null);
  const [selectedPoints, setSelectedPoints] = useState([]);
  const [bulkEditStatus, setBulkEditStatus] = useState(null);
  const [currentMarkers, setCurrentMarkers] = useState(null);
  const [showStatusSpinner, setShowStatusSpinner] = useState(false);

  useEffect(() => {
    if (bulkEditStatus && selectedPoints.length) {
      setShowStatusSpinner(true);
      const campaignId = selectedPoints[0].properties.campaignId;

      const requestData = [];
      const status = SAMPLING_POINT_STATUS.find(
        (i: any) => i.value === bulkEditStatus,
      );
      for (const point of selectedPoints) {
        requestData.push({
          pointId: point.properties.pointId,
          status: bulkEditStatus,
        });
      }

      campaignService
        .updateSamplingPointsInBulk(campaignId, requestData)
        .then(() => {
          for (const point of selectedPoints) {
            const pointUpdateInMemory = data.campaignData.samplingPoints.find(
              (i: any) => i.pointId === point.properties.pointId,
            );
            pointUpdateInMemory.status = bulkEditStatus;
            if (currentMarkers) {
              const marker = currentMarkers[point.properties.pointId];
              if (marker) {
                marker.getElement().classList.remove('marker-approved');
                marker.getElement().classList.remove('marker-in-review');
                marker.getElement().classList.remove('marker-rejected');
                marker.getElement().classList.remove('marker-skipped');
                marker.getElement().classList.add(status.markerClass);
              }
            }
          }
          const newPointsCounterData = [...pointsCounterData];
          newPointsCounterData[0].currentPoints =
            data.campaignData.samplingPoints.filter(
              (i: any) => i.status === 'APPROVED',
            ).length;
          newPointsCounterData[1].currentPoints =
            data.campaignData.samplingPoints.filter(
              (i: any) => i.status === 'IN_REVIEW',
            ).length;
          newPointsCounterData[2].currentPoints =
            data.campaignData.samplingPoints.filter(
              (i: any) => i.status === 'REJECTED',
            ).length;
          newPointsCounterData[3].currentPoints =
            data.campaignData.samplingPoints.filter(
              (i: any) => i.status === 'SKIPPED',
            ).length;
          setPointsCounterData(newPointsCounterData);
          showToast(
            'Success',
            `${selectedPoints.length} sampling points were changed to ${status.label}`,
            'success',
          );
          // filtering points on demand
          const points: any = { type: 'FeatureCollection', features: [] };
          for (const samplingPoint of data.campaignData.samplingPoints) {
            if (showApprovedLocations && samplingPoint.status === 'APPROVED') {
              points.features.push({
                geometry: {
                  coordinates: [
                    samplingPoint.longitude,
                    samplingPoint.latitude,
                  ],
                  type: 'Point',
                },
                type: 'Feature',
                properties: samplingPoint,
              });
            }
            if (showRejectedLocations && samplingPoint.status === 'REJECTED') {
              points.features.push({
                geometry: {
                  coordinates: [
                    samplingPoint.longitude,
                    samplingPoint.latitude,
                  ],
                  type: 'Point',
                },
                type: 'Feature',
                properties: samplingPoint,
              });
            }
            if (showInReviewLocations && samplingPoint.status === 'IN_REVIEW') {
              points.features.push({
                geometry: {
                  coordinates: [
                    samplingPoint.longitude,
                    samplingPoint.latitude,
                  ],
                  type: 'Point',
                },
                type: 'Feature',
                properties: samplingPoint,
              });
            }
            if (showSkippedLocations && samplingPoint.status === 'SKIPPED') {
              points.features.push({
                geometry: {
                  coordinates: [
                    samplingPoint.longitude,
                    samplingPoint.latitude,
                  ],
                  type: 'Point',
                },
                type: 'Feature',
                properties: samplingPoint,
              });
            }
          }

          map.current.getSource('samplingPointsSource').setData(points);
          // end filtering points on demand
        })
        .catch(() => {
          setBulkEditStatus(null);
          showToast(
            'Error',
            `Sorry, but we could not update the status for these sampling points. Try again later!`,
            'error',
          );
        })
        .finally(() => {
          setShowStatusSpinner(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bulkEditStatus]);

  useEffect(() => {
    if (
      campaignCsvGenerationState.lifeCycle === 'PROCEED' ||
      sendCampaignToDeveron.lifeCycle === 'PROCEED'
    ) {
      setBulkEditStatus(null);
      setDisablePointEdit(true);
      if (isMultiSelectMode) toggleMultiSelectHandler();
    }
    if (
      campaignCsvGenerationState.lifeCycle === 'ERROR' ||
      sendCampaignToDeveron.lifeCycle === 'ERROR'
    ) {
      setDisablePointEdit(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignCsvGenerationState.lifeCycle, sendCampaignToDeveron.lifeCycle]);

  function updateArea(e: any, draw: any) {
    setBulkEditStatus(null);
    draw = map.current._controls[map.current._controls.length - 1];
    const data = draw.getAll();
    const pointsInside = [];
    if (data.features.length > 0) {
      for (let polygon of data.features) {
        for (let i of map.current.getSource('samplingPointsSource')._data
          .features) {
          if (
            booleanPointInPolygon(
              [i.properties.longitude, i.properties.latitude],
              polygon,
            )
          ) {
            pointsInside.push(i);
          }
        }
      }
    } else {
      if (e.type !== 'draw.delete') alert('Click the map to draw a polygon.');
    }
    setSelectedPoints(pointsInside);
  }

  const toggleMultiSelectHandler = () => {
    const newValue = !isMultiSelectMode;
    setIsMultiselectMode(newValue);
    setCurrentPointSelected(null);

    if (newValue) {
      const draw = new MapboxDraw({
        displayControlsDefault: false,
        // Select which mapbox-gl-draw control buttons to add to the map.
        controls: {
          polygon: true,
          trash: true,
        },
        // Set mapbox-gl-draw to draw by default.
        // The user does not have to click the polygon control button first.
        defaultMode: 'draw_polygon',
      });
      setDrawControl(draw);
      map.current.addControl(draw, 'top-left');

      map.current.on('draw.create', (e: any) => {
        setTimeout(() => {
          if (draw) {
            updateArea(e, draw);
          }
        }, 1000);
      });
      map.current.on('draw.delete', (e: any) => {
        setTimeout(() => {
          if (draw) {
            updateArea(e, draw);
          }
        }, 1000);
      });
      map.current.on('draw.update', (e: any) => {
        setTimeout(() => {
          if (draw) {
            updateArea(e, draw);
          }
        }, 1000);
      });
    } else {
      map.current.removeControl(drawControl);
      setSelectedPoints([]);
      setDrawControl(null);
    }
  };

  return (
    <Box direction={'row'}>
      <div
        className='tools'
        style={{
          position: 'absolute',
        }}
      >
        <div
          style={{
            display: 'flex',
            width: '300px',
            position: 'relative',
            justifyContent: 'flex-end',
            left: 'calc(100vw - 635px)',
            gap: '8px',
            top: '8px',
          }}
        >
          <div
            style={{
              zIndex: adaptViewOpened ? -1 : 1,
            }}
          >
            <StyledTooltip
              label={`You can’t edit points ${
                data.campaignData?.status !== Statuses['in-review']
                  ? 'after'
                  : 'while'
              } generating files`}
              contentDisabled={disablePointEdit}
              disabled={!disablePointEdit}
            >
              <Button
                data-cy={'multi-select-button'}
                className={'generate-files-button'}
                onClick={toggleMultiSelectHandler}
              >
                {isMultiSelectMode ? 'Close' : 'Multi Select'}
              </Button>
            </StyledTooltip>
          </div>
          <MapAdaptViewComponent
            setAdaptViewOpened={setAdaptViewOpened}
            adaptViewOpened={adaptViewOpened}
            onAdaptViewOpened={onAdaptViewOpened}
            mapOverlayOptions={mapOverlayOptions}
            samplePointOptions={samplePointOptions}
            onAdaptVieClosed={onAdaptVieClosed}
            isMultiSelectMode={isMultiSelectMode}
          />
        </div>
      </div>
      {strataProgress >= 0 && (
        <div
          className='tools'
          style={{
            position: 'absolute',
          }}
        >
          <Box
            style={{
              position: 'relative',
              justifyContent: 'flex-end',
              left: 'calc(100vw - 970px)',
              top: '8px',
            }}
          >
            <Box
              direction='row'
              style={{
                background: '#fff',
                padding: '10px',
                alignItems: 'center',
                zIndex: '100',
                borderRadius: '5px',
                fontSize: '12px',
              }}
            >
              <Spinner size='small' />
              <Text style={{ paddingLeft: '5px', fontWeight: 'bold' }}>
                {' '}
                Loading Map Layers {strataProgress}%
              </Text>
            </Box>
          </Box>
        </div>
      )}
      <div
        ref={mapContainer}
        className='map-container'
        style={{
          width: '100%',
          height: '650px',
        }}
      />
      <Sidebar
        background='#fff'
        style={{
          minWidth: '310px',
          maxWidth: '310px',
          boxShadow:
            '0px 3px 4px rgba(0, 0, 0, 0.08), 0px 2px 3px rgba(0, 0, 0, 0.12), 0px 1px 8px rgba(0, 0, 0, 0.24)',
        }}
        footer={
          (isMultiSelectMode && selectedPoints.length && (
            <ChangeStatusInBulkComponent
              selectedPoints={selectedPoints}
              bulkEditStatus={bulkEditStatus}
              setBulkEditStatus={setBulkEditStatus}
              showStatusSpinner={showStatusSpinner}
            />
          )) ||
          ''
        }
      >
        <Nav>
          <div>
            {(!currentPointSelected || isMultiSelectMode) && (
              <Box align='center' pad='medium'>
                <Text
                  style={{
                    fontSize: '20px',
                    fontWeight: 800,
                    width: '100%',
                    textAlign: 'left',
                    lineHeight: '24px',
                  }}
                >
                  Summary
                </Text>
                <Box direction={'row'}>
                  <Stack anchor='center' style={{ marginTop: '1rem' }}>
                    <Meter
                      type='circle'
                      values={[
                        {
                          value: pointsCounterData[0].currentPoints,
                          color: '#227740',
                        },
                        {
                          value: pointsCounterData[1].currentPoints,
                          color: '#0D0D0D',
                        },
                        {
                          value: pointsCounterData[2].currentPoints,
                          color: '#CE1C3C',
                        },
                        {
                          value: pointsCounterData[3].currentPoints,
                          color: '#e39d12',
                        },
                      ]}
                      max={pointsCounterData[0].totalPoints}
                      size='small'
                      thickness='medium'
                    />
                    <Box align='center'>
                      <Box
                        direction='row'
                        align='center'
                        pad={{ bottom: 'xsmall' }}
                      >
                        <Text style={{ fontSize: '14px', fontWeight: 800 }}>
                          {pointsCounterData[0].totalPoints}
                        </Text>
                      </Box>
                      <Text style={{ fontSize: '12px', fontWeight: 400 }}>
                        points
                      </Text>
                    </Box>
                  </Stack>
                  <PointsCounterComponent
                    pointsCounterData={pointsCounterData}
                    adaptViewOpened={adaptViewOpened}
                  />
                </Box>
              </Box>
            )}
            {currentPointSelected && !isMultiSelectMode && (
              <PointDataComponent
                point={currentPointSelected}
                updateHistory={getHistory}
                history={pointHistory}
                disabled={disablePointEdit}
              />
            )}
          </div>
        </Nav>
      </Sidebar>
    </Box>
  );
};

export default ReviewPointsTabComponent;
