import { createContext } from 'react';

export interface User {
  id: string;
  username?: string;
  email: string;
  country: 'US' | 'BR';
  fullName: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  channelPartnerId: string;
  partnerType: string;
  partnerTier: string;
  language: 'en' | 'pt-BR';
  roles: string[];
}

export interface ICognitoContext {
  login: () => void;
  logout: () => void;
  getTokensOrLogin: () => Promise<{ token: string; user: User }>;
  getLoggedUserFromCognito: () => void;
  refreshSessionSilently: () => void;
  changeUserCountry: () => Promise<User>;
}

const notProvided = (): never => {
  throw new Error('Function not provided on context wrapper');
};

const initialContext: ICognitoContext = {
  login: notProvided,
  logout: notProvided,
  getTokensOrLogin: notProvided,
  getLoggedUserFromCognito: notProvided,
  refreshSessionSilently: notProvided,
  changeUserCountry: notProvided,
};

const CognitoContext = createContext<ICognitoContext>(initialContext);

export default CognitoContext;
